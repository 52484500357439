import Rivara81 from '../../../Media/Altea/Tipe 8  (1).webp'
import Rivara82 from '../../../Media/Altea/Tipe 8  (2).webp'
import Rivara83 from '../../../Media/Altea/Tipe 8  (3).webp'
import Rivara84 from '../../../Media/Altea/Tipe 8  (4).webp'



const Rivara8images =[
Rivara81,Rivara82,Rivara83,Rivara84
]

export default Rivara8images;
import React from "react";
import "./footer.scss";
import logo from "../../Media/logo_altea (2).png";
import {Link} from "react-router-dom";

const footer = () => {
  return (
    <div className="container-footer">
      <div className="container-atas">
        <div className="container-logo">
          <img className="gambar-logo" src={logo} alt="logo-Parkspring" />
        </div>
        <div className="container-deskripsi">
          <div className="alamat">
            <h1>Marketing Gallery</h1>
            <h2>Altea Boulevard</h2>
            <h3>
              Bekasi, RT.002/RW.008, Jatisampurna, Cibubur, West Java 17435
            </h3>
            <h4>Contact us : +6282213501915</h4>
            <Link to="/privacy-policy">
              <div className="footer-contact">Privacy Policy</div>
            </Link>
          </div>
          <div className="privasi"></div>
        </div>
      </div>
      <hr />
      <div className="container-bawah">© Altea Boulevard</div>
    </div>
  );
};

export default footer;
